import React from 'react'
import { useNavigate } from 'react-router-dom'
import './ErrorNotFound.css'

export default function ErrorNotFound() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <section className='notFound'>
      <h1 className='notFound__title'>404</h1>
      <p className='notFound__description'>Страница не найдена</p>
      <button type='button' className='notFound__button buttons-hover-state' onClick={goBack}>
        Назад
      </button>
    </section>
  );
}
