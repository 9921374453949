import React from "react";
import "./App.css";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import ErrorNotFound from "../ErrorNotFound/ErrorNotFound.jsx";
import Main from "../Main/Main";
import Movies from "../Movies/Movies.jsx";
import SavedMovies from "../SavedMovies/SavedMovies.jsx";
import Profile from "../Profile/Profile.jsx";
import Register from "../Register/Register.jsx";
import Login from "../Login/Login.jsx";
import authApi from "../../utils/Auth.js";
import mainApi from "../../utils/MainApi.js";

export default function App() {
  const [currentUser, setCurrentUser] = React.useState({ name: "", email: "" });
  const [isLogged, setIsLogged] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [apiMessage, setApiMessage] = React.useState("");
  const [initialized, setInitialized] = React.useState(false);
  const [refresh, setRefresh] = React.useState(true);
  const [number, setNumber] = React.useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    async function initializeApp() {
      const token = localStorage.getItem("jwt");
      if (token) {
        setIsLogged(true);
        await getUserInfo();
      }
      setInitialized(true);
    }

    initializeApp();
  }, []);

  React.useEffect(() => {
    localStorage.setItem("lastPath", location.pathname);
  }, [location.pathname]);

  React.useEffect(() => {
    async function checkTokenAndLoadUser() {
      const token = localStorage.getItem("jwt");
      if (token) {
        setIsLogged(true);
        await getUserInfo();
      }
    }

    checkTokenAndLoadUser();
  }, []);

  function setAuthState(isAuthenticated) {
    setIsLogged(isAuthenticated);
    if (!isAuthenticated) {
      localStorage.removeItem("jwt");
    }
  }

  async function handleRegister({ name, email, password }) {
    setIsLoading(true);

    try {
      const res = await authApi.register(email, password, name);
      setApiMessage("");
      setAuthState(true);
      localStorage.setItem("jwt", res.token);
      navigate("/movies");
      await getUserInfo();
    } catch (err) {
      setApiMessage(
        equalsValidationFailed(err)
          ? "При регистрации пользователя произошла ошибка"
          : err.message
      );
    } finally {
      setIsLoading(false);
    }
  }

  function equalsValidationFailed(err) {
    return err.message === "Validation failed";
  }

  async function handleLogin({ email, password }) {
    setIsLoading(true);

    try {
      const res = await authApi.authorize(email, password);
      setApiMessage("");

      // Установка состояния аутентификации и сохранение токена
      setAuthState(true);
      localStorage.setItem("jwt", res.token);

      // Получение сохраненных фильмов после успешного входа в систему
      const savedMovies = await getAllSavemovies();
      // Сохранение сохраненных фильмов в локальном хранилище
      localStorage.setItem("savedMovies", JSON.stringify(savedMovies));

      // Переход на страницу "фильмы"
      navigate("/movies");
      await getUserInfo();
    } catch (err) {
      setApiMessage(
        err.message === "Validation failed"
          ? "Неправильные почта или пароль"
          : err.message
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function getAllSavemovies() {
    try {
      const savedMovies = await mainApi.getSavedMovies();
      return savedMovies; // Возвращаем сохраненные фильмы
    } catch (err) {
      console.error("Ошибка при получении сохраненных фильмов:", err);
      return []; // Возвращаем пустой массив в случае ошибки
    }
  }

  function onSignOut() {
    localStorage.clear();
    setIsLogged(false);
    setCurrentUser({ name: "", email: "" });
    setApiMessage("");
  }

  async function getUserInfo() {
    setIsLoading(true);
    mainApi.setAuthorizationHeader(localStorage.getItem("jwt"));

    try {
      const res = await mainApi.getCurrentUser();
      setCurrentUser({ name: res.name, email: res.email });
      return res;
    } catch (err) {
      const message =
        err.message === "Validation failed"
          ? "Неправильные почта или имя"
          : err.message;
      setApiMessage(message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }

  async function handleUpdateUserInfo({ emailUser, nameUser }) {
    setIsLoading(true);
    mainApi.setAuthorizationHeader(localStorage.getItem("jwt"));
    try {
      const res = await mainApi.changeUserInfo({ emailUser, nameUser });
      setCurrentUser({ name: res.name, email: res.email });
      setApiMessage("Вы успешно изменили данные аккаунта");
    } catch (err) {
      setApiMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSaveMovie(e, movie) {
    e.preventDefault();
    const savedMovies = JSON.parse(localStorage.getItem("savedMovies")) || [];
    const movieExists = savedMovies.find((el) => el.movieId === movie.id);
    if (movieExists) {
      return;
    } else {
      try {
        const res = await mainApi.saveNewMovie(movie);
        localStorage.setItem(
          "savedMovies",
          JSON.stringify([...savedMovies, res])
        );
      } catch (err) {
        console.error("Ошибка при сохранении фильма:", err);
      }
    }
  }

  async function handleDeleteMovie(e, movie) {
    e.preventDefault();
    const savedMovies = JSON.parse(localStorage.getItem("savedMovies")) || [];
    const updatedSavedMovies = savedMovies.filter((m) => m._id !== movie._id);
    localStorage.setItem("savedMovies", JSON.stringify(updatedSavedMovies));
    try {
      await mainApi.deleteMovie(movie._id);
    } catch (err) {
      console.error(err);
    }
  }

  React.useEffect(() => {
    function blockDoubleRequest(e) {
      if (e.keyCode === 13 || e.keyCode === 32) {
        e.preventDefault();
      }
    }

    if (isLoading) {
      window.addEventListener("keydown", blockDoubleRequest);
    }

    return () => {
      if (isLoading) {
        window.removeEventListener("keydown", blockDoubleRequest);
      }
    };
  }, [isLoading]);

  const contextValue = React.useMemo(
    () => ({
      currentUser,
      isLoading,
      isLogged,
      apiMessage,
      setApiMessage,
      setIsLoading,
      handleSaveMovie,
      handleDeleteMovie,
      getAllSavemovies,
      initialized,
    }),
    [
      currentUser,
      isLoading,
      isLogged,
      apiMessage,
      setApiMessage,
      setIsLoading,
      handleSaveMovie,
      handleDeleteMovie,
      getAllSavemovies,
      initialized,
    ]
  );

  function ProtectedRoute({ children }) {
    if (!initialized) return <div>Loading...</div>;
    return isLogged ? children : <Navigate to="/" replace />;
  }

  function PublicRoute({ children }) {
    if (!initialized) return <div>Loading...</div>;
    return isLogged ? <Navigate to="/" replace /> : children;
  }

  return (
    <div className="app">
      <CurrentUserContext.Provider value={contextValue}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="*" element={<ErrorNotFound />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile
                  onSignOut={onSignOut}
                  onUpdateUserInfo={handleUpdateUserInfo}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/signin"
            element={
              <PublicRoute>
                <Login onLogin={handleLogin} />
              </PublicRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PublicRoute>
                <Register onRegister={handleRegister} />
              </PublicRoute>
            }
          />
          <Route
            path="/movies"
            element={
              <ProtectedRoute>
                <Movies />
              </ProtectedRoute>
            }
          />
          <Route
            path="/saved-movies"
            element={
              <ProtectedRoute>
                <SavedMovies />
              </ProtectedRoute>
            }
          />
        </Routes>
      </CurrentUserContext.Provider>
    </div>
  );
}
