import './Movies.css'
import React from 'react'
import Preloader from '../Preloader/Preloader'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import SearchForm from '../SearchForm/SearchForm'
import movieApi from '../../utils/MoviesApi'
import MoviesCardList from '../MoviesCardList/MoviesCardList'
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

export default function Movies () {
  const { isLoading, isLogged, setIsLoading } = React.useContext(CurrentUserContext)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [checkedFilterShortFilms, setCheckedFilterShortFilms] = React.useState(false)
  const [allMovies, setAllMovies] = React.useState([])
  const [filteredMovies, setFilteredMovies] = React.useState([])
  const [filteredShortMovies, setFilteredShortMovies] = React.useState([])
  const [movies, setMovies] = React.useState([])
  const [errorText, setErrorText] = React.useState('Начните искать свой первый фильм')

  function handleSetMovies(movies) {
    setErrorText(movies.length === 0 ? 'Ничего не найдено' : '');
    setMovies(movies);
    localStorage.setItem('movies', JSON.stringify(movies));
  }

  function handleChangeCheckedFilter(event) {
    const isChecked = !!event; 
    setCheckedFilterShortFilms(isChecked);
    localStorage.setItem('shortFilms', JSON.stringify(isChecked));
    handleSetMovies(isChecked ? filteredShortMovies : filteredMovies);
  }
  
  function handleChangeSearchQuery(event) {
    setSearchQuery(event.target.value);
  }

  function filterShortMovies (movies) {
    return movies.filter(movie => movie.duration < 40)
  }

  function filterMovies(arrMovies, searchQuery, shortFilms) {
    const trimmedQuery = searchQuery.toLowerCase().trim();
    
    const moviesByQuery = arrMovies.filter(movie => {
      const nameRu = movie.nameRU.toLowerCase();
      const nameEn = movie.nameEN.toLowerCase();
      return nameRu.includes(trimmedQuery) || nameEn.includes(trimmedQuery);
    });
  
    const filteredShortMovies = filterShortMovies(moviesByQuery);
    setFilteredShortMovies(filteredShortMovies);

    if (shortFilms) {
      return filteredShortMovies;
    }
  
    setFilteredMovies(moviesByQuery); 
    return moviesByQuery;
  }

  function handleSetFilteredMovies (arrMovies, query, checkbox) {
    const filteredMovies = filterMovies(arrMovies, query, checkbox)
    handleSetMovies(filteredMovies)
  }

  async function handleSearchSubmit() {
    setIsLoading(true);
    localStorage.setItem('searchQuery', searchQuery);
    const safeCheckedFilterShortFilms = checkedFilterShortFilms || false;
    localStorage.setItem('shortFilms', JSON.stringify(safeCheckedFilterShortFilms));
  
    try {
      const res = allMovies.length ? allMovies : await movieApi.getAllMovies();
      if (!allMovies.length) {
        setAllMovies(res);
      }
      handleSetFilteredMovies(res, searchQuery, safeCheckedFilterShortFilms);
    } catch (err) {
      setErrorText('Во время запроса произошла ошибка');
      console.error(err); 
    } finally {
      setIsLoading(false); 
    }
  }

  React.useEffect(() => {
    const storedMovies = localStorage.getItem('movies');
    const storedSearchQuery = localStorage.getItem('searchQuery') || '';
    const storedShortFilms = JSON.parse(localStorage.getItem('shortFilms')) || false;
  
    setSearchQuery(storedSearchQuery);
    setCheckedFilterShortFilms(storedShortFilms);
  
    if (storedMovies) {
      const parsedMovies = JSON.parse(storedMovies);
      handleSetFilteredMovies(parsedMovies, storedSearchQuery, storedShortFilms);
    }
  }, []);

  return (
    <>
      <Header isLogged={isLogged} mainPage={false} />
      
      <main className='moviesPage'>
        <SearchForm
          searchQuery={searchQuery}
          checkedFilter={checkedFilterShortFilms}
          handleChangeSearchQuery={handleChangeSearchQuery}
          handleChangeCheckedFilter={handleChangeCheckedFilter}
          onSubmit={handleSearchSubmit}
        />
        <MoviesCardList moviesSavedPage={false} movies={movies} errorText={errorText}/>
      </main>

      <Footer />
      {isLoading && <Preloader />}
    </>
  )
}
