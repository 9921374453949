import React from "react";
import "./MoviesCard.css";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { useLocation } from "react-router-dom";

export default function MoviesCard({ item, isLiked, handleClickDeleteTest}) {
  const {
    handleSaveMovie,
    handleDeleteMovie,
  } = React.useContext(CurrentUserContext);

  const [liked, setLiked] = React.useState(isLiked);

  React.useEffect(() => {
    setLiked(isLiked);
  }, [isLiked]);

  const imgUrl = item.image.url
    ? `https://api.nomoreparties.co${item.image.url}`
    : item.image;

  const ONE_HOUR = 60;

  const location = useLocation();

  function durationInHours(mins) {
    const hours = Math.trunc(mins / ONE_HOUR);
    const minutes = mins % ONE_HOUR;
    return `${hours}ч ${minutes}м`;
  }

  function handleLikeClick(e, item) {
    e.preventDefault();
    if (liked) {
      handleDeleteMovie(e, item);
    } else {
      handleSaveMovie(e, item);
    }
    setLiked(!liked);
  }

  const handleDeleteInSaved = (e, item) => {
    handleDeleteMovie(e, item);
    handleClickDeleteTest(item)
  };

  return (
    <article className="card">
      <a
        href={item.trailerLink}
        className="card__link links-hover-state"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={imgUrl} alt="постер фильма" className="card__images" />
      </a>

      <div className="card__box">
        <div className="card__info">
          <p className="card__name">{item.nameRU}</p>
          <p className="card__duration">{durationInHours(item.duration)}</p>
        </div>
        {location.pathname === "/movies" ? (
          <button
            className={`buttons-hover-state card__button card__likeButton ${
              liked && "card__likeButton_active"
            }`}
            onClick={(e) => handleLikeClick(e, item)}
          ></button>
        ) : (
          <button
            className="buttons-hover-state card__button card__deleteButton"
            onClick={(e) => handleDeleteInSaved(e, item)}
            type="button"
          ></button>
        )}
      </div>
    </article>
  );
}
