import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { useAdjustSize } from '../../hooks/useAdjustSize';
import Navigation from '../Navigation/Navigation';
import UserLinks from '../UserLinks/UserLinks';


function AuthLinks() {
  return (
      <nav className='header__links-profile'>
          <Link to='/signup' className='buttons-hover-state header__button-signUp'>Регистрация</Link>
          <Link to='/signin' className='buttons-hover-state header__button-signIn'>Войти</Link>
      </nav>
  );
}

export default function Header({ isLogged, mainPage }) {
    const [isOpened, setIsOpened] = React.useState(false);
    const { isScreen839 } = useAdjustSize();
  
    const handleToggleMenu = () => setIsOpened(prev => !prev);
    const closeBurgerMenu = () => setIsOpened(false);

    const headerClass = ['header', mainPage ? 'header_grey' : 'header_no-color'].join(' ');
    const buttonClass = isLogged && isScreen839 ? 'header__buttons' : 'header__buttons header__buttons-end';

    return (
        <>
            <header className={headerClass}>
                <Link to='/'>
                    <div className='header__logo links-hover-state' />
                </Link>

                <div className={buttonClass}>
                    {isLogged ? (
                        isScreen839 ? (
                            <UserLinks />
                        ) : (
                            <button
                                type='button'
                                className='buttons-hover-state header__burger'
                                onClick={handleToggleMenu}
                            />
                        )
                    ) : (
                      <AuthLinks /> 
                    )}
                </div>
            </header>
      
            <Navigation isOpened={isOpened} onClose={closeBurgerMenu} />
        </>
    );
}
