import './Navigation.css'
import usePopupClose from '../../hooks/usePopupClose.js'
import { Link, useLocation } from 'react-router-dom'


const navLinks = [
  { path: '/', label: 'Главная' },
  { path: '/movies', label: 'Фильмы' },
  { path: '/saved-movies', label: 'Сохранённые фильмы' }
];

export default function BurgerMenu({ isOpened, onClose }) {
  usePopupClose(isOpened, onClose);
  const location = useLocation();

  return (
    <nav className={isOpened ? 'navigate navigate_opened' : 'navigate'}>
      <div className='navigate__container'>
        <button
          type='button'
          onClick={onClose}
          aria-label='Закрыть меню'
          className='navigate__buttonClose'
        ></button>

        <ul className='navigate__links'>
          {navLinks.map(link => (
            <li key={link.path} className='navigate__links-element'>
              <Link
                to={link.path}
                className={`links-hover-state navigate__link ${location.pathname === link.path ? 'navigate__link-active' : ''}`}
              >
                {link.label}
              </Link>
            </li>
          ))}
        </ul>

        <Link
          to={'/profile'}
          className='buttons-hover-state navigate__buttonProfile'
        >
          <div className='navigate__buttonProfile-img' />
          Аккаунт
        </Link>
      </div>
    </nav>
  );
}
