import './AuthInput.css'


export default function AuthInput ({ typeInput, labelText, idInput, error, ...props }) {
  
  return (
    <div className='authInput'>
      <label className='authInput__text' htmlFor={idInput}>{labelText}</label>
      <input
        type={typeInput}
        id={idInput}
        className='authInput__input'
        autoComplete='off'
        placeholder=' '
        {...props}
      />
      <span className='authInput__error'>{error}</span>
    </div>
  )
}
