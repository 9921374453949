import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Header/Header.css';

function UserLinks() {
    const location = useLocation();

    return (
      <>
        <div className="header__links-movies">
            <Link
              to={'/movies'}
              className={`links-hover-state header__link ${
                location.pathname === '/movies' ? 'header__link-active' : ''
              }`}
              >
                Фильмы
            </Link>

            <Link
                to={'/saved-movies'} className={`links-hover-state header__link ${
                  location.pathname === '/saved-movies'
                    ? 'header__link-active'
                    : ''
                }`}
              >
                Сохранённые фильмы
            </Link>
        </div>
        <div>
            <Link
                to='/profile'
                className='buttons-hover-state header__button-profile'
            >
                <div className='header__button-images' />
                Аккаунт
            </Link>
        </div>
      </>
    );
}

export default UserLinks;
