import './AuthForm.css'


export default function AuthForm({
  buttonText,
  children,
  isValid,
  idForm,
  classForm = '',
  onSubmit,
  apiMessage
}) {
  const buttonClass = `authForm__button ${
    isValid ? 'buttons-hover-state' : 'authForm__button-disabled'
  }`;
  return (
    <form
      action="#"
      method="POST"
      id={idForm}
      noValidate
      name={idForm}
      className={`${classForm} authForm`}
      onSubmit={onSubmit}
    >
      {children}
      
      <div className='authForm__box'>
        {apiMessage && <span className='authForm__apiMessage'>{apiMessage}</span>}
        <button
          className={buttonClass}
          type='submit'
          form={idForm}
          disabled={!isValid}
        >
          {buttonText}
        </button>
      </div>
    </form>
  );
}
