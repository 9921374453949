import './Profile.css'
import React from 'react'
import Header from '../Header/Header.jsx'
import { useNavigate } from 'react-router-dom'
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { useValidation } from '../../hooks/useValidation.js'
import Preloader from '../Preloader/Preloader.jsx'


export default function Profile ({ onSignOut, onUpdateUserInfo }) {
  const { currentUser, isLoading, isLogged, setApiMessage, apiMessage } = React.useContext(CurrentUserContext)
  const { values, errors, isValid, handleChange, setValues, setIsValid } = useValidation()
  const [editInfo, setEditInfo] = React.useState(false)

  const navigate = useNavigate()
  function handlerClickExitFromAccount (event) {
    event.preventDefault()
    navigate('/')
    onSignOut()
  }

  function editInfoHandler (event) {
    event.preventDefault()
    setEditInfo(true)
    setApiMessage('')
  }

  function handleSubmit (event) {
    event.preventDefault()
    onUpdateUserInfo({
      nameUser: values.userNameInput,
      emailUser: values.userEmailInput
    })
    setEditInfo(false)
  }

  React.useEffect(() => {
    if (currentUser) {
      setValues({
        userNameInput: currentUser.name,
        userEmailInput: currentUser.email
      })
    }
  }, [setValues, currentUser])

  React.useEffect(() => {
    if (
      currentUser.name === values.userNameInput &&
      currentUser.email === values.userEmailInput
    ) {
      setIsValid(false)
    }
  }, [setIsValid, values, currentUser])

  React.useEffect(() => {
    if (editInfo) {
      const userNameInput = document.querySelector('#userNameInput')
      userNameInput.focus()
    }
  }, [editInfo])

  return (
    <>
      <Header isLogged={isLogged} mainPage={false} />
      <main className='main'>
        <section className='profile'>
          <h3 className='profile__title'>Привет, {currentUser.name}!</h3>
          
          <form
            action='#'
            method='POST'
            id='profileForm'
            className='profile__form'
            name='profileForm'
            onSubmit={handleSubmit}
            noValidate
          >
            <label className='profile__container'>
              <span className='profile__input-name'>Имя</span>
              <input
                type='text'
                id='userNameInput'
                className='profile__input'
                autoComplete='off'
                value={values.userNameInput || ''}
                onChange={handleChange}
                disabled={!editInfo}
                pattern='^[^\s][A-Za-zА-Яа-яЁё - \s]+$'
                placeholder=' '
                minLength={2}
                maxLength={30}
                required
              />

              <span className='profile__input-error'>
                {errors.userNameInput === 'Введите данные в правильном формате.'
                  ? `Поле должно быть заполнено и может содержать только латиницу,
                кириллицу, пробел или дефис`
                  : errors.userNameInput}
              </span>

            </label>
            <label className='profile__container'>
              <span className='profile__input-name'>E-mail</span>
              <input
                type='email'
                id='userEmailInput'
                className='profile__input'
                autoComplete='off'
                value={values.userEmailInput || ''}
                pattern='^[^\s][\w]+@[a-zA-Z]+\.[a-zA-Z]{2,30}$'
                onChange={handleChange}
                disabled={!editInfo}
                placeholder=' '
                required
              />

              <span className='profile__input-error'>
                {errors.userEmailInput}
              </span>
            </label>

              <div className='profile__boxButtonSave'>
              <span
                className={`profile__apiMessage ${
                  apiMessage === 'Вы успешно изменили данные аккаунта'
                    ? 'profile__apiMessage_good'
                    : ''
                } `}
              >
                {apiMessage}
              </span>
              <button
                className={`profile__button-save ${
                  isValid ? 'buttons-hover-state' : 'profile__button-save-disabled'
                }`}
                type='submit'
                hidden={!editInfo}
                form='profileForm'
                disabled={!isValid}
              >
                Сохранить
              </button>
            </div>
          </form>

          <div className='profile__panel'>
            <button
              type='button'
              className='profile__panel-button profile__panel-buttonEdit buttons-hover-state'
              onClick={editInfoHandler}
              hidden={editInfo}
            >
              Редактировать
            </button>
            
            <button
              type='button'
              className='profile__panel-button profile__panel-buttonExit buttons-hover-state'
              onClick={handlerClickExitFromAccount}
              hidden={editInfo}
            >
              Выйти из аккаунта
            </button>
          </div>
        </section>

        {isLoading && <Preloader />}
      </main>
    </>
  )
}
