import React, { useState, useEffect } from "react";
import "./MoviesCardList.css";
import MoviesCard from "../MoviesCard/MoviesCard.jsx";
import { useAdjustSize } from "../../hooks/useAdjustSize.js";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";

export default function MoviesCardList({ moviesSavedPage, movies, errorText }) {
  const { handleDeleteMovie } = React.useContext(CurrentUserContext);
  const [savedMovies, setSavedMovies] = useState(
    JSON.parse(localStorage.getItem("savedMovies")) || []
  );
  const [isMount, setIsMount] = React.useState(true);
  const [showList, setShowList] = React.useState([]);
  const { width, isScreen767, isScreen839, isScreen989 } = useAdjustSize();
  const [cardsShowParams, setCardsShowParams] = React.useState({
    sum: 0,
    more: 0,
  });

  useEffect(() => {
    if (!moviesSavedPage) {
      setShowList(movies.slice(0, cardsShowParams.sum));
    } else {
      setShowList(movies);
    }
  }, [handleDeleteMovie, savedMovies, movies, moviesSavedPage, cardsShowParams.sum]);

  React.useEffect(() => {
    let params = { sum: 5, more: 2 };

    if (width >= 1280) {
      params = { sum: 8, more: 4 };
    } else if (width >= 990) {
      params = { sum: 12, more: 3 };
    } else if (width >= 768) {
      params = { sum: 8, more: 2 };
    }

    setCardsShowParams(params);

    return () => setIsMount(false);
  }, [width, isMount]);

  React.useEffect(() => {
    if (movies.length && !moviesSavedPage) {
      const res = movies.filter((item, index) => index < cardsShowParams.sum);
      setShowList(res);
    }
  }, [movies, moviesSavedPage, cardsShowParams.sum]);

  React.useEffect(() => {
    if (moviesSavedPage) {
      setShowList(movies);
    }
  }, [movies, moviesSavedPage]);

  function checkIfMovieIsSaved(movieId) {
    return savedMovies.some((savedMovie) => savedMovie.movieId === movieId);
  }

  const handleClickMoreMovies = () => {
    const newShowCount = showList.length + cardsShowParams.more;
    setShowList(movies.slice(0, newShowCount));
  };

  const handleClickDeleteTest = (item) => {
    setShowList((prevList) => prevList.filter((m) => m._id !== item._id));
  }

  function setSavedCards() {
    return showList.map((item) => {
      const key = moviesSavedPage ? item._id : item.id;
      return (
        <li key={key}>
          <MoviesCard item={item} moviesSavedPage={moviesSavedPage} handleClickDeleteTest={handleClickDeleteTest}/>
        </li>
      );
    });
  }


  function setCards() {
    return showList.map((item) => {
      const isLiked = checkIfMovieIsSaved(item.id);
      const savedMovieCard = savedMovies.find(
        ({ movieId }) => movieId === item.id
      );
      const key = moviesSavedPage ? item._id : item.id;
    
      return (
        <li key={key}>
          <MoviesCard
            item={{ ...item, _id: savedMovieCard ? savedMovieCard._id : null }}
            moviesSavedPage={moviesSavedPage}
            isLiked={isLiked}
          />
        </li>
      );
    });
  }

  return (
    <section className={`cardList ${moviesSavedPage ? "cardList_saved" : ""}`}>
      <h1 hidden={true}>Список фильмов</h1>

      {showList.length > 0 ? (
        <ul
          className={`cardList__items ${
            (showList.length === 3 && showList.length && isScreen989) ||
            (showList.length === 2 && showList.length && isScreen839) ||
            (showList.length === 1 && showList.length && !isScreen767)
              ? "cardList__items_left"
              : ""
          }`}
        >
          {!moviesSavedPage ? setCards() : setSavedCards()}
        </ul>
      ) : (
        <p className="cardList__error">{errorText}</p>
      )}

        {showList.length > 0 && showList.length !== movies.length && (
          <button
            type="button"
            className={`cardList__buttonMore buttons-hover-state ${
              moviesSavedPage ? "cardList__buttonMore-none" : ""
            }`}
            onClick={handleClickMoreMovies}
          >
            Ещё
          </button>
        )}
    </section>
  );
}
