import React from 'react'
import './SavedMovies.css'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import MoviesCardList from '../MoviesCardList/MoviesCardList'
import Preloader from '../Preloader/Preloader'
import SearchForm from '../SearchForm/SearchForm'
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import mainApi from '../../utils/MainApi.js'


export default function SavedMovies ({ movies }) {
  const { isLoading, isLogged } = React.useContext(CurrentUserContext)
  const [checkedFilterShortFilms, setCheckedFilterShortFilms] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [showList, setShowList] = React.useState([])
  const [savedMovies, setSavedMovies] = React.useState([]);

  const handleChangeSearchQuery = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    localStorage.setItem('savedSearchQuery', query);  // Сохраняем запрос в LocalStorage
  };

  function handleChangeCheckedFilter() {
    setCheckedFilterShortFilms(prev => {
      const newState = !prev;
      localStorage.setItem('shortFilmsChecked', JSON.stringify(newState));  // Сохраняем состояние чекбокса в LocalStorage
      return newState;
    });
    handleSearch();
  }

  const handleSearch = () => {
    const filtered = filterMovies(savedMovies, searchQuery, checkedFilterShortFilms);
    setShowList(filtered);
  };

  function filterShortMovies (movies) {
    return movies.filter(movie => movie.duration < 40)
  }

  const filterMovies = (movies, query, shortFilms) => {
    const normalizedQuery = query.toLowerCase().trim();
    const filteredMovies = movies.filter(movie =>
      movie.nameRU.toLowerCase().includes(normalizedQuery) ||
      movie.nameEN.toLowerCase().includes(normalizedQuery)
    );
    return shortFilms ? filterShortMovies(filteredMovies) : filteredMovies;
  };

  function handleSearchSubmit() {
    const filtered = filterMovies(showList, searchQuery, checkedFilterShortFilms);
    setShowList(filtered);
  }

  React.useEffect(() => {
    const savedQuery = localStorage.getItem('savedSearchQuery') || '';
    setSearchQuery(savedQuery);
  
    const savedCheckboxState = JSON.parse(localStorage.getItem('shortFilmsChecked')) || false;
    setCheckedFilterShortFilms(savedCheckboxState);  // Восстанавливаем состояние чекбокса из LocalStorage
  
    async function fetchAndSetSavedMovies() {
      try {
        const savedMoviesData = await mainApi.getSavedMovies();
        setSavedMovies(savedMoviesData);
        setShowList(savedMoviesData);
      } catch (err) {
        console.error('Failed to fetch saved movies:', err);
      }
    }
    fetchAndSetSavedMovies();
  }, []);

  React.useEffect(() => {
    handleSearch();
  }, [searchQuery, checkedFilterShortFilms, savedMovies]);

  return (
    <>
      <Header isLogged={isLogged} mainPage={false} />

      <main className='savedMoviesPage'>
        <SearchForm
          searchQuery={searchQuery}
          handleChangeSearchQuery={handleChangeSearchQuery}
          handleChangeCheckedFilter={handleChangeCheckedFilter}
          checkedFilter={checkedFilterShortFilms}
          onSubmit={handleSearchSubmit}
        />
        <MoviesCardList
          moviesSavedPage={true}
          movies={showList}
          errorText={'Ничего не найдено'}
        />
      </main>

      <Footer />
      {isLoading && <Preloader />}
    </>
  )
}
